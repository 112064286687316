/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.code-editor {
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
}

/* Set widths on the form inputs since otherwise they're 100% wide */
input,
select,
textarea {
    max-width: 280px;
}

input[type="radio"] {
    margin-right: 5px;
}

.horizontal-spaced {
    margin-left: 50px;
}

/* Carousel */
.carousel-caption {
    z-index: 10 !important;
}

    .carousel-caption p {
        font-size: 20px;
        line-height: 1.4;
    }

@media (min-width: 768px) {
    .carousel-caption {
        z-index: 10 !important;
    }
}

/* http://stephanwagner.me/only-css-loading-spinner */
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.spinner-container {
    position: relative;
}

/* Inspired by https://loading.io/css/ */
.spinner:before {
    position: absolute;
    z-index: 100;
    content: " ";
    left: 50%;
    top: 50%;
    width: 28vmin;
    height: 28vmin;
    margin: -14vmin;
    border-radius: 50%;
    border: 8px solid #68217a;
    border-color: #68217a transparent #68217a transparent;
    animation: lds-dual-ring 1.2s linear infinite;
    animation-delay: 0.2s;
    opacity: 0;
}

@keyframes lds-dual-ring {
    0% {
        opacity: 1;
        transform: rotate(0deg);
    }
    100% {
        
        opacity: 1;
        transform: rotate(360deg);
    }
}

.navbar {
    background-color: #333333;
}

.navbar-brand:not(:hover) .title-highlight {
    color: #b28bbc;
}

@media (min-width: 576px) {
    .navbar-expand-sm .navbar-collapse { display: inline-flex !important; }
}

.conversion-controls {
    text-align: right;
    margin: 10px 0px -10px 0px;
}