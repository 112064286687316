
.octo-svg {
    border: 0;
    color: #222222;
    fill: #ffffff;
    position: absolute;
    right: 0;
    top: -12px;
}

.octo-arm {
    -ms-transform-origin: 130px 106px;
    -o-transform-origin: 130px 106px;
    -webkit-transform-origin: 130px 106px;
    transform-origin: 130px 106px;
}

/* BEGIN https://github.com/tholman/github-corners/blob/master/license.md */
.github-corner:hover .octo-arm {
    animation: octocat-wave 560ms ease-in-out
}

@keyframes octocat-wave {
    0%,100% {
        transform: rotate(0)
    }

    20%,60% {
        transform: rotate(-25deg)
    }

    40%,80% {
        transform: rotate(10deg)
    }
}
/* END https://github.com/tholman/github-corners/blob/master/license.md */

@media (max-width:768px) {
    .github-corner {
        display: none
    }
}

a.navbar-brand {
    white-space: normal;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }